import React, { Component } from "react";

import "react-datepicker/dist/react-datepicker.css";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { baseURL } from "../Helpers/Constants";
import googleIcon from "../assets/images/google.svg";
class LoginScreen extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.state = {
			isInProgress: false,
			redirectToReferrer: false,
			error: "",
			innerHieght: window.innerHeight,
		};
	}
	componentDidMount() {
		window.addEventListener("resize", () => {
			if (window.innerHeight !== this.state.innerAvailableHeight) {
				this.setState({ innerHieght: window.innerHeight });
			}
		});
	}
	onLoginResponseSuccess = (data) => {
		// console.log('onLoginResponseSuccess called', data.profileObj.email);
		// console.log("onLoginResponseSuccess called", data.profileObj.email);

		axios
			.post(
				baseURL() + "/api/v1/users/authenticate",
				{
					email: data.profileObj.email, //production
					// email: "irum.ishfaq@discretelogix.com", //staging
					// email: "rumbli123@gmail.com",
					// email: "malik.arslan@discretelogix.com",
					// email: "sheeza.sarwar@discretelogix.com",
					// email: "hamza.rasheed@discretelogix.com",
					// email: "joe@omegamasonry.com",
					// email: "info@omegamasonry.com",
				},
				{
					headers: {
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then((res) => {
				// console.log("-----------response--------", res);
				if (res.data.success) {
					localStorage.setItem("userData", JSON.stringify(res.data.data));
					localStorage.setItem("userId", res.data.data._id);
					localStorage.setItem("jwtToken", res.data.data.jwt_token);
					localStorage.setItem("nodeJwtToken", res.data.data.jwt_token);

					localStorage.setItem("isLoggedIn", true);
					localStorage.setItem("username", res.data.data.name);
					localStorage.setItem("email", res.data.data.user);
					localStorage.setItem(
						"userSelectedColumn",
						JSON.stringify(res.data.data.selectedFields)
					);

					localStorage.setItem(
						"savedFilters",
						JSON.stringify(res.data.data.filters)
					);

					localStorage.setItem(
						"userPdfShareNumber",
						JSON.stringify(res.data.data.numberList)
					);
					localStorage.setItem(
						"userPdfShareEmail",
						JSON.stringify(res.data.data.emailList)
					);
					localStorage.setItem("isDeveloper", res.data.data.isDeveloper);
					localStorage.setItem("isAdmin", res.data.data.role);
					localStorage.setItem("permissions", res.data.data.permissions);

					window.location.reload();
					// this.setState({ redirectToReferrer: true });
				} else {
					this.setState({ error: res.data.message });
				}
			})
			.catch((error) => {
				console.log("Error:", error);
				this.setState({ error: error.message });
			});
	};
	onLoginResponseFailure = (data) => {
		// console.log('onLoginResponseFailure called', data);
		// console.log(data);
		localStorage.setItem("isLoggedIn", false);
	};
	render() {
		// const { from } = this.props.location.state || { from: { pathname: "/" } };
		// const { redirectToReferrer } = this.state;
		// if (redirectToReferrer) {
		//   return <Redirect to={from} />;
		// }
		return (
			<div className='container h-100 d-flex justify-content-center'>
				<div
					className='jumbotron'
					style={{ marginTop: 170, backgroundColor: "transparent" }}
				>
					<div className='row' style={{ height: 45 }}>
						<p
							style={{
								fontSize: 34,
								// fontWeight: 40,
								color: "#0A84FE",
								textAlign: "left",
								letterSpacing: 0,
								fontFamily: "SF Pro Display Light",
							}}
						>
							Welcome to
						</p>
					</div>
					<div className='row' style={{ height: 158 }}>
						<p
							style={{
								fontSize: 48,
								// fontWeight: 500,
								color: "#FFFFFF",
								lineHeight: 1.1,
								letterSpacing: 0,
								textAlign: "left",
								fontFamily: "SF Pro Display Semibold",
							}}
						>
							Lead <br /> Management <br /> System
						</p>
					</div>
					<div className='row' style={{ marginTop: 60 }}>
						<GoogleLogin
							clientId='414852214154-ir07ak63fa5bt91rl4ue9lhh5jbqduds.apps.googleusercontent.com'
							buttonText='Login with Google'
							render={(renderProps) => (
								<button
									style={{
										height: 60,
										width: 300,
										fontSize: 15,
										color: "#454f63",
										backgroundColor: "#f7f7fa",
										borderRadius: 12,
										border: "none",
										outline: "none",
									}}
									onClick={renderProps.onClick} //production and local
									// onClick={this.onLoginResponseSuccess} //staging
									disabled={renderProps.disabled}
								>
									<span
										style={{
											fontSize: 20,

											textAlign: "center",
											fontFamily: "SF Pro Display Medium",
										}}
									>
										<i style={{ marginTop: -4 }}>
											<img src={googleIcon} alt='' />
										</i>
										<span
											style={{
												fontSize: 20,
												letterSpacing: -1,
												color: "#454F63",
												margin: 40,
												// fontFamily: 'SF Pro Display Medium'
											}}
										>
											Sign in with Google
										</span>
									</span>
								</button>
							)}
							onSuccess={this.onLoginResponseSuccess}
							onFailure={this.onLoginResponseFailure}
							// onFailure={this.onLoginResponseFailure}
							cookiePolicy={"single_host_origin"}
						/>
					</div>
					<div
						className='row'
						style={{ marginTop: 5, justifyContent: "center" }}
					>
						<p
							style={{
								color: "red",
								fontSize: 15,
								fontFamily: "SF Pro Text Medium",
							}}
						>
							{this.state.error}
						</p>
					</div>
				</div>
			</div>
		);
	}
}
export default LoginScreen;
