import React from "react";
import { Line } from "react-chartjs-2";

function LineChart({ chartData }) {
  return (
    <Line
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: { beginAtZero: true },
              // gridLines: {
              //     display: false,
              //     drawBorder: true
              // },
              // barThickness: 50,
            },
          ],
          yAxes: [
            {
              ticks: { beginAtZero: true },
              // gridLines: {
              //     display: false,
              //     drawBorder: true
              // }
            },
          ],
        },
      }}
    />
  );
}

export default LineChart;
